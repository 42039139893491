<template>
  <b-dropdown ref="dropdown" right no-caret size="lg" variant="none" class="cart-dropdown">
    <template slot="button-content">
      <font-awesome-icon :icon="['fas', 'shopping-cart']" class="icon-for-dropdown" />
      <b-badge variant="info">{{ cartCount }}</b-badge>
    </template>
    <b-dropdown-header>
      <b-row>
        <b-col>
          <font-awesome-icon :icon="['fas', 'shopping-cart']" />
          <b-badge variant="info">{{ cartCount }}</b-badge>
        </b-col>
        <b-col class="text-right">
          Total: <span class="cart-dropdown__product-price">{{ total }}</span>
        </b-col>
      </b-row>
    </b-dropdown-header>
    <div class="cart-dropdown__cart-body">
      <b-row v-for="product in products" :key="product.id" class="cart-dropdown__cart-body__cart-item-row" align-v="center">
        <b-col cols="2">
          <img :src="product.url_foto_listing" class="img-avatar max-w-full" />
        </b-col>
        <b-col>
          <b-row>
            <b-col>
              <div class="cart-dropdown__cart-body__cart-item-row__product-name">
                {{ product.product_name }}
              </div>
            </b-col>
          </b-row>
          <b-row align-v="end">
            <b-col class="cart-dropdown__product-price" cols="5">{{ product.total_with_vat }}</b-col>
            <b-col class="cart-dropdown__cart-body__cart-item-row__quantity" cols="5"> Cantitate: {{ product.quantity }}</b-col>
            <b-col class="text-right" cols="2">
              <a v-b-tooltip.hover class="text-danger" href="" title="Șterge produs din cos" @click.prevent="destroy(product.id)">
                <font-awesome-icon :icon="['fas', 'trash-alt']" />
              </a>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <p v-if="isCartEmpty" class="my-3">Nu sunt produse în coș!</p>
    </div>
    <div v-if="!isCartEmpty" class="cart-dropdown__cart-footer border-top text-center">
      <b-button :to="'/cart'" variant="primary" @click="closeDropdown"> Vizualizați coșul </b-button>
    </div>
  </b-dropdown>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'

  export default {
    props: {},
    computed: {
      ...mapGetters({
        products: 'cart/products',
        cartCount: 'cart/numberOfProductsInCart',
        total: 'cart/total',
        isCartEmpty: 'cart/empty',
      }),
    },
    beforeMount() {
      this.$store.dispatch('cart/getCart')
    },
    methods: {
      ...mapActions({
        destroy: 'cart/destroy',
      }),
      closeDropdown() {
        this.$refs.dropdown.hide('true')
      },
    },
  }
</script>

<style lang="scss">
  .cart-dropdown {
    &__product-price {
      font-size: 13px;
      color: #20a8d8;
    }
    &__cart-body {
      padding: 0 15px;
      max-height: 500px;
      overflow-y: scroll;
      &__cart-item-row {
        padding-top: 10px;
        padding-bottom: 10px;
        &__product-price {
          font-size: 13px;
        }
        &__quantity {
          color: #333333;
        }
      }
    }

    &__cart-footer {
      padding: 15px 0;
    }
    .icon-for-dropdown {
      font-size: 25px;
    }
    .dropdown-menu {
      width: 400px !important;
    }
  }
</style>
