import { getField, updateField } from 'vuex-map-fields'
/* eslint-disable camelcase */

export const state = () => ({
  order: {
    history: {},
    evio_history: {},
  },
  order_status: {
    NEW: 'NEW',
    PROCESSED: 'PROCESSED',
    DELIVERED: 'DELIVERED',
    CANCELED: 'CANCELED',
    COURIER_READY: 'COURIER_READY',
  },
  delivery_method: {
    ADDRESS: 'ADDRESS',
    PICKUP: 'PICKUP',
    COURIER_PICKUP: 'COURIER_PICKUP',
  },
  payment_method: {
    CASH: 'CASH',
    PAYMENT_ORDER: 'PAYMENT_ORDER',
    PAYMENT_ORDER_ON_TIME: 'PAYMENT_ORDER_ON_TIME',
  },
  processed_type: {
    OFFICE: 'OFFICE',
    DELIVERY: 'DELIVERY',
    COURIER: 'COURIER',
  },
  locations: {
    DEPOSIT: 'MILITARI',
    HEADQUARTER: 'PIPERA',
    PRODUCER: 'PRODUCER',
  },
  arriving_points: {
    HEADQUARTER: 'Pipera',
    DEPOSIT: 'Militari',
    EXTERN: 'EXTERN',
  },
  delivery_types: {
    COURIER: 'COURIER',
    PERSONAL: 'PERSONAL',
    PICKUP: 'PICKUP',
  },
  canceled_reasons: {},
  couriers: [],
  isLoading: false,
})

export const mutations = {
  SET_ORDER(state, order) {
    state.order = order
  },
  SET_LOADING(state, value) {
    state.isLoading = value
  },
  SET_ORDER_CONSTANTS(state, data) {
    state.order_status = data.order_status
    state.delivery_method = data.delivery_method
    state.payment_method = data.payment_method
    state.processed_type = data.processed_type
    state.locations = data.locations
    state.processed_type = data.processed_type
    state.arriving_points = data.arriving_points
    state.delivery_types = data.delivery_types
    state.couriers = data.couriers
    state.canceled_reasons = data.canceled_reasons
  },

  UPDATE_PRODUCT_IMPORTER(state, { index, value }) {
    state.order.products[index].pivot.importer_id = value
  },

  UPDATE_PRODUCT_WORKPOINT(state, { index, value }) {
    state.order.products[index].pivot.work_point_id = value
  },
  UPDATE_PRODUCT_ARRIVINGPOINT(state, { index, value }) {
    state.order.products[index].pivot.arriving_point = value
  },
  UPDATE_PRODUCT_DELIVERYTYPE(state, { index, value }) {
    state.order.products[index].pivot.delivery_type = value
  },
  UPDATE_PRODUCT_DOT(state, { index, value }) {
    state.order.products[index].pivot.DOT = value
  },

  UPDATE_PRODUCT_PRODUCTION_COUNTRY(state, { index, value }) {
    state.order.products[index].pivot.production_country = value
  },

  UPDATE_PRODUCT_MENTIONS(state, { index, value }) {
    state.order.products[index].pivot.mentions = value
  },

  UPDATE_PRODUCT_PRODUCER_MENTIONS(state, { index, value }) {
    state.order.products[index].pivot.producer_mentions = value
  },
  UPDATE_PRODUCT_QUANTITY(state, { index, value }) {
    state.order.products[index].pivot.quantity = value
  },
  UPDATE_PRODUCT_SELLING_PRICE(state, { index, value }) {
    state.order.products[index].pivot.selling_price = value
  },

  UPDATE_ORDER_HISTORY(state, { data }) {
    state.order.history = data.history
    state.order.evio_history = data.evio_history
  },

  UPDATE_ORDER_PRODUCTS(state, { data }) {
    state.order.products = data
  },

  UPDATE_ORDER_PRICES(state, { data }) {
    state.order.total = data.total
    state.order.advance_payment = data.advance_payment
    state.order.delivery_cost = data.delivery_cost
    state.order.discount = data.discount
    state.order.discount_type = data.discount_type
    state.order.cash_on_delivery = data.cash_on_delivery
  },

  UPDATE_ORDER_AWB(state, data) {
    state.order.awb = data.awb
    state.order.awb_status = data.awbStatus
  },

  UPDATE_AWB_AND_CURIER(state, { data }) {
    state.order.awb = data.awb
    state.order.awb_status = data.awbStatus
    state.order.courier = data.courier
  },

  UPDATE_AWB_STATUS(state, data) {
    state.order.awb_status = data.awbStatus
  },

  UPDATE_ORDER_STATE(state, data) {
    state.order.state = data.state
  },

  UPDATE_ORDER_STATES(state, data) {
    state.order.state = data.state
    state.order.processed_type = data.processed_type
    state.order.location = data.location
    state.order.locked = data.locked
    state.order.canceled_reason = data.canceled_reason
  },

  UPDATE_ORDER_LOCATION(state, data) {
    state.order.location = data
  },
  UPDATE_ORDER_LOCKED(state, data) {
    state.order.locked = data
  },
  updateField,
}

export const actions = {
  async updateEstimationDeliveryDate({ commit, state, dispatch }) {
    commit('SET_LOADING', true)
    const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, { estimation_delivery_date: state.order.estimation_delivery_date })

    commit('SET_LOADING', false)
    this.$toast.success(response.status)

    await dispatch('fetchAwbData', state.order.id)
    await dispatch('fetchOrderHistory')
  },
  async updateOrderLocation({ commit, state, dispatch }) {
    commit('SET_LOADING', true)
    const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, { location: state.order.location })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchAwbData')
    await dispatch('fetchOrderHistory')
  },

  async updateOrderProcessedType({ commit, state, dispatch }) {
    commit('SET_LOADING', true)
    const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, {
      processed_type: state.order.processed_type,
      location: null,
    })
    commit('SET_LOADING', false)

    commit('UPDATE_ORDER_LOCATION', null)
    this.$toast.success(response.status)

    await dispatch('fetchAwbData')
    await dispatch('fetchOrderHistory')
  },

  async updateOrderStatus({ commit, state, dispatch }) {
    try {
      commit('SET_LOADING', true)

      const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, { state: state.order.state })
      this.$toast.success(response.status)
      commit('SET_LOADING', false)
      commit('UPDATE_ORDER_LOCKED', response.locked)
    } catch (error) {
      this.$toast.error(error.response.data.message)
      commit('SET_LOADING', false)
      commit('UPDATE_ORDER_STATE', error.response.data)
      // this.$store. order.state = error.response.data.state
    }

    await dispatch('fetchOrderStates')
    await dispatch('fetchAwbData')
    await dispatch('fetchOrderProducts')
    await dispatch('fetchOrderHistory')
  },

  async updateAdvancePayment({ commit, state, dispatch }) {
    commit('SET_LOADING', true)
    const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, { advance_payment: state.order.advance_payment })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchOrderPrices')
    await dispatch('fetchAwbData')
    await dispatch('fetchOrderHistory')
  },

  async updateDiscount({ commit, state, dispatch }) {
    commit('SET_LOADING', true)
    const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, {
      discount_type: state.order.discount_type,
      discount: state.order.discount,
    })
    commit('SET_LOADING', false)
    this.$toast.success(response.status)

    await dispatch('fetchAwbData')
    await dispatch('fetchOrderPrices')
    await dispatch('fetchOrderHistory')
  },

  async updateDeliveryOption({ commit, state, dispatch }, delivery_option) {
    commit('SET_LOADING', true)

    const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, { delivery_option })

    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchAwbData')
    await dispatch('fetchOrderPrices')
    await dispatch('fetchOrderHistory')
  },

  async updatePaymentOption({ commit, state, dispatch }) {
    commit('SET_LOADING', true)
    const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, { payment_option: state.order.payment_option })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchAwbData')
    await dispatch('fetchOrderPrices')
    await dispatch('fetchOrderHistory')
  },

  async updateCourierMentions({ commit, state, dispatch }) {
    commit('SET_LOADING', true)
    const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, { courier_mentions: state.order.courier_mentions })
    commit('SET_LOADING', false)
    this.$toast.success(response.status)

    await dispatch('fetchAwbData')
    await dispatch('fetchOrderHistory')
  },

  async updateLogisticMentions({ commit, state, dispatch }) {
    commit('SET_LOADING', true)
    const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, { logistic_mentions: state.order.logistic_mentions })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchOrderHistory')
  },

  async updateSalesMentions({ commit, state, dispatch }) {
    commit('SET_LOADING', true)
    const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, { sales_mentions: state.order.sales_mentions })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchOrderHistory')
  },
  async updateFinancialMentions({ commit, state, dispatch }) {
    commit('SET_LOADING', true)
    const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, { financial_mentions: state.order.financial_mentions })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchOrderHistory')
  },
  async updateCanceledReason({ commit, state, dispatch }) {
    commit('SET_LOADING', true)

    const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, { canceled_reason: state.order.canceled_reason })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchOrderHistory')
  },
  // eslint-disable-next-line camelcase
  async updateDeliveryAddress({ commit, state, dispatch }, delivery_address_id) {
    commit('SET_LOADING', true)

    const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, { delivery_address_id })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchAwbData')
    await dispatch('fetchOrderHistory')
  },

  // eslint-disable-next-line camelcase
  async updateImporter({ commit, state, dispatch }, { product_id, importer_id }) {
    // eslint-disable-next-line camelcase
    commit('SET_LOADING', true)

    const response = await this.$axios.$patch(`admin/orders/productInOrder/${state.order.id}/importer/${product_id}`, {
      importer_id,
    })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    // Update workpoint in the store
    const index = state.order.products.findIndex((product) => product.id === product_id)
    if (index !== -1) {
      const work_point_id = null
      commit('UPDATE_PRODUCT_WORKPOINT', { index, value: work_point_id })
    }

    await dispatch('updateWorkPoint', { product_id, work_point_id: null })
  },

  async updateWorkPoint({ commit, state, dispatch }, { product_id, work_point_id }) {
    commit('SET_LOADING', true)

    const response = await this.$axios.$patch(`admin/orders/productInOrder/${state.order.id}/workpoint/${product_id}`, {
      work_point_id,
    })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchAwbData')
    await dispatch('fetchOrderProducts')
    await dispatch('fetchOrderHistory')
  },

  async updateArrivingPoint({ commit, state, dispatch }, { product_id, arriving_point }) {
    commit('SET_LOADING', true)

    const response = await this.$axios.$patch(`admin/orders/productInOrder/${state.order.id}/arrivingpoint/${product_id}`, {
      arriving_point,
    })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchAwbData')
    await dispatch('fetchOrderHistory')
  },

  async updateDeliveryType({ commit, state, dispatch }, { product_id, delivery_type }) {
    commit('SET_LOADING', true)

    const response = await this.$axios.$patch(`admin/orders/productInOrder/${state.order.id}/deliverytype/${product_id}`, {
      delivery_type,
    })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchAwbData')
    await dispatch('fetchOrderHistory')
  },

  async updateDot({ commit, state, dispatch }, { product_id, dot }) {
    try {
      commit('SET_LOADING', true)

      const response = await this.$axios.$patch(`admin/orders/productInOrder/${state.order.id}`, { product_id, dot })
      commit('SET_LOADING', false)
      this.$toast.success(response.status)

      await dispatch('fetchOrderHistory')

      return true
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors.dot[0]
        this.$toast.error(errorMessage)
      } else {
        this.$toast.error('An error occurred.')
      }
      return false
    }
  },
  async updateProductPrice({ commit, state, dispatch }, { product_id, selling_price }) {
    try {
      commit('SET_LOADING', true)

      const response = await this.$axios.$patch(`admin/orders/productInOrder/${state.order.id}`, {
        product_id,
        selling_price,
      })
      commit('SET_LOADING', false)

      this.$toast.success(response.status)

      await dispatch('fetchAwbData')
      await dispatch('fetchOrderPrices')
      await dispatch('fetchOrderHistory')

      return true
    } catch (error) {
      commit('SET_LOADING', false)

      if (error.response && error.response.data && error.response.data.errors) {
        const errorMessage = error.response.data.errors.selling_price[0]
        this.$toast.error(errorMessage)
      } else {
        this.$toast.error('An error occurred.')
      }
      return false
    }
  },

  async updateProductionCountry({ commit, state, dispatch }, { product_id, production_country }) {
    commit('SET_LOADING', true)

    const response = await this.$axios.$patch(`admin/orders/productInOrder/${state.order.id}`, {
      product_id,
      production_country,
    })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)
    await dispatch('fetchOrderHistory')
  },

  async updateMentions({ commit, state, dispatch }, { product_id, mentions }) {
    const response = await this.$axios.$patch(`admin/orders/productInOrder/${state.order.id}`, { product_id, mentions })
    this.$toast.success(response.status)

    await dispatch('fetchOrderHistory')
  },

  async updateProducerMentions({ commit, state, dispatch }, { product_id, producer_mentions }) {
    commit('SET_LOADING', true)

    const response = await this.$axios.$patch(`admin/orders/productInOrder/${state.order.id}`, {
      product_id,
      producer_mentions,
    })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchOrderHistory')
  },

  async updateQuantity({ commit, state, dispatch }, { product_id, quantity }) {
    commit('SET_LOADING', true)

    const response = await this.$axios.$patch(`admin/orders/productInOrder/${state.order.id}/quantity/${product_id}`, { quantity })
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchAwbData')
    await dispatch('fetchOrderPrices')
    await dispatch('fetchOrderHistory')
  },
  async logCurrentlyActiveUser({ dispatch, state }) {
    await this.$axios.$post(`admin/orders/${state.order.id}/log-currently-active-user`, {})
    await dispatch('fetchOrderHistory')
  },

  async deleteProduct({ commit, state, dispatch }, id) {
    commit('SET_LOADING', true)

    const response = await this.$axios.$delete(`admin/orders/productInOrder/${state.order.id}/${id}`)
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchOrderProducts')
    await dispatch('fetchOrderPrices')
    await dispatch('fetchAwbData')
    await dispatch('fetchOrderHistory')
  },

  async addProduct({ commit, state, dispatch }, id) {
    commit('SET_LOADING', true)

    const response = await this.$axios.$post(`admin/orders/productInOrder/${state.order.id}/${id}`)
    commit('SET_LOADING', false)

    this.$toast.success(response.status)

    await dispatch('fetchOrderProducts')
    await dispatch('fetchOrderPrices')
    await dispatch('fetchAwbData')
    await dispatch('fetchOrderHistory')
  },
  async searchProducts({ commit, state, dispatch }, input) {
    const { data } = await this.$axios.$get(`admin/products-search?input=${input}`)
    return data
  },

  async validateIban({ state }) {
    const config = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'x-api-key': '7vdZEzEjtnfnwuHujFDyQdh9z-zsSnb4dML-mWx1_Qc4yWJZcw',
    }

    const { valid } = await this.$axios.$get(`https://api.openapi.ro/v1/validate/iban/${state.company.iban}`, config)

    state.isValidIban = valid
  },

  async generateInvoice({ commit, state, dispatch }, draft) {
    try {
      commit('SET_LOADING', true)

      const response = await this.$axios.$post(`admin/orders/generateInvoice/${state.order.id}`, { isDraft: draft })
      commit('SET_LOADING', false)

      this.$toast.success(response.status)
    } catch (error) {
      this.$toast.error(error.response.data.status)
    }
    await dispatch('fetchOrderHistory')
  },

  async printAwb({ state, commit, dispatch }) {
    try {
      commit('SET_LOADING', true)
      const response = await this.$axios.$get(`admin/orders/printOrderAwb/${state.order.id}`, { responseType: 'blob' })

      // debugger
      // // Extract order state from response headers
      // const orderState = response.headers['order-state']

      const blob = new Blob([response], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `order_awb${state.order.awb}.pdf`
      link.click()

      window.URL.revokeObjectURL(url)
      commit('SET_LOADING', false)

      this.$toast.success('PDF downloaded successfully!')

      await dispatch('fetchOrderStates')
      await dispatch('fetchOrderHistory')
    } catch (error) {
      this.$toast.error(JSON.stringify(error))
      commit('SET_LOADING', false)
    }
  },

  async downloadInvoice({ state, commit, dispatch }, evio_invoice_id) {
    try {
      commit('SET_LOADING', true)
      const response = await this.$axios.$get(`admin/order/downloadInvoice/${evio_invoice_id}`, { responseType: 'blob' })

      const blob = new Blob([response], { type: 'application/pdf' })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = `order_${evio_invoice_id}.pdf`
      link.click()

      window.URL.revokeObjectURL(url)
      commit('SET_LOADING', false)

      this.$toast.success('PDF downloaded successfully!')
    } catch (error) {
      this.$toast.error(JSON.stringify(error))
      commit('SET_LOADING', false)
    }
  },

  async fetchOrderPrices({ commit, state }) {
    const response = await this.$axios.$get('admin/orders/orderprices/' + state.order.id)

    commit('UPDATE_ORDER_PRICES', response)
  },

  async fetchOrderProducts({ commit, state }) {
    const response = await this.$axios.$get('admin/orders/products/' + state.order.id)

    commit('UPDATE_ORDER_PRODUCTS', response)
  },

  async fetchOrderHistory({ commit, state }) {
    const response = await this.$axios.$get('admin/orders/history/' + state.order.id)

    commit('UPDATE_ORDER_HISTORY', response)
  },

  async fetchOrderStates({ commit, state }) {
    const response = await this.$axios.$get('admin/orders/states/' + state.order.id)

    commit('UPDATE_ORDER_STATES', response.data)
  },

  async fetchOrderConstants({ commit }) {
    const response = await this.$axios.$get('admin/orders/constants')

    commit('SET_ORDER_CONSTANTS', response)
  },

  async fetchOrder({ commit, dispatch }, orderId) {
    await dispatch('importers/fetchImporters', null, { root: true })
    // await dispatch('counties/fetchCounties', null, {root: true})
    await dispatch('fetchOrderConstants')

    const response = await this.$axios.$get('admin/orders/' + orderId)
    commit('SET_ORDER', response.data)

    await dispatch('fetchAwbStatus')

    return response.data
  },

  async fetchAwbData({ commit, state }) {
    const response = await this.$axios.$get(`admin/orders/awbData/${state.order.id}`)

    this.$toast.success(response.data.status)
    commit('UPDATE_ORDER_AWB', response.data)
  },
  async fetchAwbStatus({ commit, state }) {
    const response = await this.$axios.$get(`admin/orders/awbStatus/${state.order.id}`)

    this.$toast.success(response.data.status)
    commit('UPDATE_AWB_STATUS', response.data)
  },
  async updateCourier({ commit, state, dispatch }) {
    // const response = await this.$axios.$patch(`admin/orders/${state.order.id}`, {courier: state.order.courier})
    commit('SET_LOADING', true)

    const response = await this.$axios.get(`admin/orders/changeCourier/${state.order.id}/${state.order.courier}`)
    commit('SET_LOADING', false)

    if (response.data.data.status) this.$toast.success(response.data.data.status)
    if (response.data.data.warning) this.$toast.error(response.data.data.warning)
    commit('UPDATE_AWB_AND_CURIER', response.data)

    await dispatch('fetchOrderHistory')
  },

  async sendReturnNotice({ commit, state, dispatch }, products) {
    commit('SET_LOADING', true)

    const response = await this.$axios.post(`admin/orders/sendReturnNotice/${state.order.id}`, { products })
    commit('SET_LOADING', false)

    this.$toast.success(response.data.status)

    await dispatch('fetchOrderHistory')
  },
}

export const getters = {
  order(state) {
    return state.order
  },
  order_status(state) {
    return state.order_status
  },
  delivery_method(state) {
    return state.delivery_method
  },
  payment_method(state) {
    return state.payment_method
  },
  locations(state) {
    return state.locations
  },
  arriving_points(state) {
    return state.arriving_points
  },
  delivery_types(state) {
    return state.delivery_types
  },
  processedType(state) {
    return state.processed_type
  },
  canceled_reasons(state) {
    return state.canceled_reasons
  },
  couriers(state) {
    return state.couriers
  },
  isLoading(state) {
    return state.isLoading
  },

  getField,
}
